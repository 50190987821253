import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import BlogContentSection from "../components/BlogContentSection";
import BlogHeader from "../components/BlogHeader";
import Layout from "../components/layout";
import BlogCta from "../components/BlogCta";
import { Col, Container, Row } from "react-bootstrap";
import { StickyContainer, Sticky } from "react-sticky";
import { useMediaQuery } from "react-responsive";

const PostTemplate = ({ data: { wpPage, site, wpComponent } }) => {
	const siteUrl = site?.siteMetadata?.siteUrl;
	const [activeRole, setActiveRole] = useState(null);
	const ctaData = wpComponent?.blogCtaFields; // Contains { heading, body }

	const isDesktop = useMediaQuery({ minWidth: 992 });

	const { seoFields } = wpPage;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: `Resources`,
				item: {
					url: `${siteUrl}/resources`,
					id: `${siteUrl}/resources`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${seoFields?.metaTitle}`,
				item: {
					url: `${siteUrl}/resources/${wpPage.slug}`,
					id: `${siteUrl}/resources/${wpPage.slug}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.metaTitle || wpPage?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/resources/${wpPage.slug}`,
					title: `${seoFields?.opengraphTitle || wpPage?.title}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.sourceUrl}`,
							width: `${seoFields?.image?.mediaDetails.width}`,
							height: `${seoFields?.image?.mediaDetails.height}`,
							alt: `${seoFields?.image?.altText}`,
						},
					],
				}}
			/>
			<BlogHeader site={site} blogFields={wpPage} />
			<section className="py-5 py-lg-7" style={{ background: "#F9FAFB" }}>
				<Container>
					<StickyContainer>
						<Row className="g-6 g-lg-5">
							<Col lg={8}>
								<BlogContentSection site={site} wpPage={wpPage} />
							</Col>
							{ctaData && (
								<Col xs={12} lg={4}>
									{isDesktop ? (
										<Sticky>
											{({ style, isSticky }) => (
												<div
													style={{
														boxShadow: "none",
														backgroundColor: "#F9FAFB",
														...style,
														...(isSticky ? { marginTop: "50px" } : {}),
													}}
												>
													<BlogCta
														heading={ctaData.heading}
														body={ctaData.body}
													/>
												</div>
											)}
										</Sticky>
									) : (
										// On smaller screens, simply render the CTA with a bit of padding
										<div>
											<BlogCta heading={ctaData.heading} body={ctaData.body} />
										</div>
									)}
								</Col>
							)}
						</Row>
					</StickyContainer>
				</Container>
			</section>
		</Layout>
	);
};

export default PostTemplate;

export const pageQuery = graphql`
	query PostById($id: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		wpComponent(slug: { eq: "blog-cta" }) {
			blogCtaFields {
				body
				heading
			}
		}

		wpPage: wpPost(id: { eq: $id }) {
			author {
				node {
					name
					lastName
				}
			}
			content
			slug
			id
			title
			date
			seoFields {
				opengraphTitle
				opengraphDescription
				metaTitle
				metaDescription
				fieldGroupName
				productSchema
				image {
					altText
					sourceUrl
					mediaDetails {
						height
						width
					}
				}
			}
			blogFields {
				description
				numberOfMinsRead
				authorName
				authorRole
				authorImage {
					altText
					localFile {
						childImageSharp {
							gatsbyImageData(
								formats: [WEBP, AUTO]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: COVER }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				featuredImage {
					altText
					localFile {
						childImageSharp {
							gatsbyImageData(
								formats: [WEBP, AUTO]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: COVER }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
			categories {
				nodes {
					name
				}
			}
		}
	}
`;
