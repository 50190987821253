import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaFacebookF, FaLink, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { SafeHtmlParser } from "./SafeHtmlParser";

const BlogContentSection = ({ wpPage, site }) => {
	const siteUrl = site?.siteMetadata?.siteUrl;
	const postUrl = `${siteUrl}/${wpPage.slug}`;
	const postTitle = encodeURIComponent(wpPage.title);
	const postSummary = encodeURIComponent(wpPage.blogFields?.description || "");

	// Share links
	const linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
		postUrl
	)}&title=${postTitle}&summary=${postSummary}`;

	const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
		postUrl
	)}`;

	const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
		postUrl
	)}&text=${postTitle}`;

	// Get the first 4 categories
	const categories = wpPage.categories?.nodes?.slice(0, 4) || [];

	return (
		<div>
			<Row className=" justify-content-center">
				{/* Left Column: Main Content */}
				<Col xs={12} className="blog-inner-content">
					<SafeHtmlParser htmlContent={wpPage.content} />
				</Col>

				{/* Right Column: "Spread the word" & Categories */}
				<Col xs={12}>
					<div
						style={{
							borderBottom: `1px solid ${wpPage.blogFields.colour}`,
						}}
						className="py-5"
					>
						<Row className="align-items-end ">
							<Col xs={12} md={4} xl={3}>
								<h5 className="mb-3 f-6 ">Share this post</h5>
								<div>
									<ul className="list-inline ms-0 ps-0 mb-0">
										<li className="list-inline-item me-1">
											<a
												href="#"
												onClick={() =>
													navigator.clipboard.writeText(window.location.href)
												}
												style={{ textDecoration: "none" }}
											>
												<div className="icon-container-content ">
													<FaLink className="fs-5" />
												</div>
											</a>
										</li>
										<li className="list-inline-item mx-1">
											<a
												href={linkedinShareUrl}
												target="_blank"
												rel="noopener noreferrer"
												style={{ textDecoration: "none" }}
											>
												<div className="icon-container-content ">
													<FaLinkedin className="fs-5" />
												</div>
											</a>
										</li>
										<li className="list-inline-item mx-1">
											<a
												href={twitterShareUrl}
												target="_blank"
												rel="noopener noreferrer"
												style={{ textDecoration: "none" }}
											>
												<div className="icon-container-content ">
													<FaXTwitter className="fs-5" />
												</div>
											</a>
										</li>
										<li className="list-inline-item mx-1">
											<a
												href={facebookShareUrl}
												target="_blank"
												rel="noopener noreferrer"
												style={{ textDecoration: "none" }}
											>
												<div className="icon-container-content ">
													<FaFacebookF className="fs-5" />
												</div>
											</a>
										</li>
									</ul>
								</div>
							</Col>

							{/* Categories Section */}
							<Col xs={12} md={8} xl={9} className="text-md-end mt-4 mt-md-0">
								<div className="d-flex flex-wrap gap-2 justify-content-md-end">
									{categories.map((category, i) => (
										<div key={i} className="p-1 px-2 position-relative">
											<div
												style={{
													background: "rgba(233, 78, 27, 0.25)",
													borderRadius: "6px",
												}}
												className="position-absolute w-100 h-100 start-0 top-0"
											></div>
											<p
												style={{ zIndex: 2, color: "#E94E1B" }}
												className=" position-relative sora-medium py-0 my-0"
											>
												{category.name}
											</p>
										</div>
									))}
								</div>
							</Col>
						</Row>
						<hr className="mt-5" style={{ color: "#E94E1B", opacity: 0.5 }} />
					</div>
				</Col>
			</Row>

			{/* Author Section */}
			<Row className=" justify-content-center">
				{/* Left Column: Main Content */}
				<Col xs={12}>
					<div className="d-flex align-items-center">
						{wpPage.blogFields.authorImage?.localFile?.childImageSharp
							?.gatsbyImageData && (
							<GatsbyImage
								className=""
								image={
									wpPage.blogFields.authorImage.localFile.childImageSharp
										.gatsbyImageData
								}
								alt={wpPage.blogFields.authorImage?.altText || "Author Image"}
								style={{
									width: "60px",
									height: "60px",
									borderRadius: "14px",
								}}
							/>
						)}
						<div className="d-block ms-3">
							<h6 className=" pb-0 mb-0">
								{wpPage?.author?.node?.name} {wpPage?.author?.node?.lastName}
							</h6>
							{/* <p
								className="mb-0 pb-0"
								style={{ color: wpPage.blogFields.colour }}
							>
								{wpPage.blogFields.authorRole}
							</p> */}
						</div>
					</div>
				</Col>
			</Row>
			<style>{`
        .icon-container-content {
		color:#282C53;
          width: 35px;
          height: 35px;
          border: 0.5px solid #444444;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: border-color 0.3s ease;
        }
        .icon-container-content:hover {
          border-color: #E94E1B;
		  color: #E94E1B;
        }
      `}</style>
		</div>
	);
};

export default BlogContentSection;
