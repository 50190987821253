import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import { Form, Button } from "react-bootstrap";
import { SafeHtmlParser } from "../components/SafeHtmlParser"; // Adjust path if needed

/**
 * BlogCta
 *
 * Displays:
 * - A heading (via SafeHtmlParser)
 * - A body text (via SafeHtmlParser)
 * - A single input field (Website URL)
 * - A "Continue" button
 * Uses Formspree to handle submission.
 */
const BlogCta = ({ heading, body }) => {
	// Replace "xvoypqyd" with your own Formspree form ID
	const [state, handleSubmit] = useForm("mknalbrg");

	if (state.succeeded) {
		// After a successful submission, do a redirect or show a thank-you message
		// For example, a quick redirect:
		return <div className="d-none">{(window.location = "/thank-you")}</div>;
	}

	return (
		<div>
			<div
				className="w-100"
				style={{
					borderRadius: "12px",
					backgroundColor: "#fff",
					padding: "1.5rem",
					boxShadow: "0px 1px 13.4px 0px rgba(55, 73, 166, 0.06)",
				}}
			>
				{/* Heading */}
				{heading && (
					<h4 style={{ fontWeight: 700, marginBottom: "0.75rem" }}>
						<SafeHtmlParser htmlContent={heading} />
					</h4>
				)}

				{/* Body */}
				{body && (
					<p style={{ marginBottom: "1.25rem" }}>
						<SafeHtmlParser htmlContent={body} />
					</p>
				)}

				{/* Form */}
				<Form onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="websiteUrl">
						<Form.Label className="fw-bold">Your website URL</Form.Label>
						<Form.Control
							type="url"
							name="websiteUrl"
							placeholder="http://"
							required
						/>
						<ValidationError
							prefix="websiteUrl"
							field="websiteUrl"
							errors={state.errors}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="email">
						<Form.Label className="fw-bold">Your email</Form.Label>
						<Form.Control
							type="email"
							name="email"
							placeholder="info@acme.com"
							required
						/>
						<ValidationError
							prefix="email"
							field="email"
							errors={state.errors}
						/>
					</Form.Group>

					<Button
						type="submit"
						disabled={state.submitting}
						className="w-100 text-white button-reverse-gradient"
					>
						Continue
					</Button>
				</Form>
			</div>
		</div>
	);
};

export default BlogCta;
