import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import { format } from "date-fns";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { FaFacebookF, FaLink, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import Navigation from "./navigation";
import { FaArrowRightLong } from "react-icons/fa6";

/**
 * BlogHeader
 *
 * Props:
 * - blogFields: the post data (title, date, categories, blogFields)
 * - site: used for share links (site.siteMetadata.siteUrl)
 */
const BlogHeader = ({ blogFields, site }) => {
	const {
		title,
		slug,
		date,
		author,
		categories,
		blogFields: {
			featuredImage,
			authorName,
			numberOfMinsRead,
			description,
		} = {},
	} = blogFields;

	// Format date
	const formattedDate = date ? format(new Date(date), "dd MMM yyyy") : "";
	// Show first category or default
	const categoryName = categories?.nodes?.[0]?.name || "Uncategorized";

	// Construct the featured image
	const imageData = featuredImage?.localFile
		? getImage(featuredImage.localFile.childImageSharp.gatsbyImageData)
		: null;

	// Share links (if you want them in the header):
	const siteUrl = site?.siteMetadata?.siteUrl;
	const postUrl = `${siteUrl}/resources/${slug}`;
	const postTitle = encodeURIComponent(title);
	const postSummary = encodeURIComponent(description || "");

	const linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
		postUrl
	)}&title=${postTitle}&summary=${postSummary}`;
	const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
		postUrl
	)}`;
	const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
		postUrl
	)}&text=${postTitle}`;

	// Inline styles
	const headerSectionStyle = {
		background:
			"linear-gradient(266deg, var(--Color-Brand-Blue-1, #2D3158) 0%, var(--Color-Brand-Blue-2, #1F2140) 100%)",
		color: "#FFFFFF",
	};

	return (
		<section className="pb-5 pb-lg-7" style={headerSectionStyle}>
			<Navigation />
			<Container className="pt-md-5">
				<Row className="align-items-center g-5 g-md-3 g-lg-7">
					{/* Left column: category, title, meta, share */}
					<Col lg={6} md={8} className="mb-4 mb-md-0">
						{/* Category info, e.g. "Blog > Marketing" */}
						<div className="d-flex gap-2 align-items-center mb-4 text-white">
							<Link className="white-link sora-light py-0 my-0" to="/resources">
								Blog
							</Link>{" "}
							<FaArrowRightLong style={{ color: "#E94E1B" }} />{" "}
							<p className="py-0 my-0 ">{categoryName}</p>
						</div>
						<div className="d-md-none mb-4">
							{imageData ? (
								<GatsbyImage
									image={imageData}
									alt={featuredImage?.altText || title}
									className="w-100"
									style={{
										borderRadius: "12px",
									}}
								/>
							) : (
								<div
									style={{
										backgroundColor: "#ccc",
										width: "100%",
										height: "250px",
										borderRadius: "8px",
									}}
								/>
							)}
						</div>
						{/* Title */}
						<h1 className="display-5">{title}</h1>

						{/* Meta: Author, date, read time */}
						<div className="mt-3">
							<p className="pb-0 mb-1">
								By{" "}
								<span className="sora-medium">
									{author.node.name} {author?.node?.lastName}
								</span>
							</p>
							<p style={{ color: "#DEDEDE" }}>
								{formattedDate} • {numberOfMinsRead || 1} min read
							</p>
						</div>
						<p className="sora-medium pt-md-4">Share this post</p>
						{/* Share this post */}
						<div>
							<div>
								<ul className="list-inline ms-0 ps-0 mb-0">
									<li className="list-inline-item me-1">
										<a
											href="#"
											onClick={(e) => {
												e.preventDefault();
												navigator.clipboard.writeText(postUrl);
											}}
											style={{ textDecoration: "none" }}
										>
											<div className="icon-container-head ">
												<FaLink className="fs-5" />
											</div>
										</a>
									</li>
									<li className="list-inline-item mx-1">
										<a
											href={linkedinShareUrl}
											target="_blank"
											rel="noopener noreferrer"
											style={{ textDecoration: "none" }}
										>
											<div className="icon-container-head ">
												<FaLinkedin className="fs-5" />
											</div>
										</a>
									</li>
									<li className="list-inline-item mx-1">
										<a
											href={twitterShareUrl}
											target="_blank"
											rel="noopener noreferrer"
											style={{ textDecoration: "none" }}
										>
											<div className="icon-container-head ">
												<FaXTwitter className="fs-5" />
											</div>
										</a>
									</li>
									<li className="list-inline-item mx-1">
										<a
											href={facebookShareUrl}
											target="_blank"
											rel="noopener noreferrer"
											style={{ textDecoration: "none" }}
										>
											<div className="icon-container-head ">
												<FaFacebookF className="fs-5" />
											</div>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</Col>

					{/* Right column: featured image */}
					<Col className="d-none d-md-block" lg={6} md={4}>
						{imageData ? (
							<GatsbyImage
								image={imageData}
								alt={featuredImage?.altText || title}
								className="w-100"
								style={{
									borderRadius: "12px",
								}}
							/>
						) : (
							<div
								style={{
									backgroundColor: "#ccc",
									width: "100%",
									height: "250px",
									borderRadius: "8px",
								}}
							/>
						)}
					</Col>
				</Row>
			</Container>
			<style>{`
        .icon-container-head {
		color:white;
          width: 35px;
          height: 35px;
          border: 0.5px solid white;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: border-color 0.3s ease;
        }
        .icon-container-head:hover {
          border-color: #E94E1B;
		  color: #E94E1B;
        }
      `}</style>
		</section>
	);
};

export default BlogHeader;
